import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import posthog from 'posthog-js'

import { HeaderInput } from 'components/search-autocomplete/inputs/header-input'
import { InitDropdown } from 'components/search-autocomplete/init-dropdown'
import { useSearchModal } from 'components/search-modal/hooks'
import type { SearchModalProps } from 'components/search-modal'
import type { ResultsProps, AutoCompleteProps } from 'components/search-autocomplete'

import useCustomTranslation from 'lib/hooks/useTranslation'
import useSearch from 'lib/hooks/useSearch'
import useRouteMatch from 'lib/hooks/useRouteMatch'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'
import { getAutoSuggestFilter, searchExposureOnEmptyResults } from 'lib/utils/search'
import { buildPath, logError } from 'lib/utils'

import { PRODUCT_ROUTE, SEARCH_ROUTE } from 'lib/constants/routes'
import { DATA_LAYER_EVENT } from 'lib/constants'

import { AUTO_SUGGEST_SEACH_QUERY } from 'gql/queries/search'

const SearchAutocomplete = dynamic<AutoCompleteProps>(
  () => import('components/search-autocomplete').then((module) => module.SearchAutocomplete),
  { ssr: false }
)
const SearchAutoCompleteResults = dynamic<ResultsProps>(
  () => import('components/search-autocomplete').then((module) => module.SearchAutocompleteResults),
  { ssr: false }
)
const SearchAutoCompleteSeeMore = dynamic<SearchAutoCompleteSeeMore>(
  () => import('components/search-autocomplete').then((module) => module.SearchAutocompleteSeeMore),
  { ssr: false }
)
const SearchModal = dynamic<SearchModalProps>(
  () => import('components/search-modal').then((module) => module.SearchModal),
  { ssr: false }
)

import s from './styles.module.scss'

const Search = ({
  hasSearch,
  searchInputAsIcon,
  destinationId,
}: {
  hasSearch: boolean
  searchInputAsIcon?: boolean
  destinationId?: string
}) => {
  const router = useRouter()
  const { isMobileView } = useGlobalContext()
  const { trackEvent } = useAppData()
  const { t } = useCustomTranslation('common')

  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const countryId = router.query.countryId as string

  // get `destinationId` from `productSlug` in case its PDP
  const isProductRoute = Boolean(useRouteMatch(PRODUCT_ROUTE))
  const destinationIdFromSlug = isProductRoute && destinationId
  const _destinationId = destinationIdFromSlug || (router.query.destinationId as string)

  const { isSearchModalOpen, closeSearchModal, openSearchModal, loader } = useSearchModal()
  /**
   * ****** SEARCH COMPONENT DESIGN ******
   * Search feature uses the composition of components & hooks & filter to form a complete search feature
   *
   * 1. Components
   *  a. SearchAutocomplete: search field
   *  b. Results: used to populate auto suggestion
   *  c. Header & Footer: used to prepend/append in populated auto suggestion
   *
   * 2. useSearch Hook
   *
   * 3. filter exposed to useSearch
   *  Parent component using the hook responsible to provide the search filter before api call
   *  filter can be different page to page and according to use case we have
   *
   * EXAMPLE:
   *
   *  |__ Page 1
   *    |
   *    |__ filter () =>
   *    |__ data = useSearch({...filter})
   *    |__ <SearchAutocomplete><SearchHeaderComp... /><Results options={data...}><SearchFooterComp... /></SearchAutocomplete>
   */

  const schema = {
    initialQuery: null,
    initialPageSize: 8,
  }

  const {
    query,
    data,
    error,
    searching,
    handleChange: doSearch,
  } = useSearch({
    initialQuery: hasSearch ? schema.initialQuery : null,
    filter: () => getAutoSuggestFilter({ destinationId, countryId }),
    searchQuery: AUTO_SUGGEST_SEACH_QUERY.query,
    onSearchQueryComplete: (queryData) => {
      // exposure event will be triggered by component if there are results
      searchExposureOnEmptyResults({
        query,
        destinations: queryData?.[AUTO_SUGGEST_SEACH_QUERY.queryName]?.destinations,
        products: queryData?.[AUTO_SUGGEST_SEACH_QUERY.queryName]?.products,
        trackEvent: trackEvent.current,
      })
    },
  })

  const searchResults = useMemo(
    () => ({
      destinations: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName]?.destinations,
      queries: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName]?.queries,
      products: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName]?.products,
    }),
    [data]
  )

  const hideSeeMore = useMemo(() => {
    return !Object.values(searchResults).reduce((total, item = []) => item.length + total) && searching
  }, [searchResults, searching])

  const onSearchInputRef = useCallback((node: HTMLInputElement) => {
    searchInputRef.current = node
  }, [])

  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error])

  const handleSearchInputClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMobileView) return

    e.stopPropagation()
    searchInputRef.current?.blur()
    openSearchModal()
  }

  const handleTrackEventCallback = (params: any) => {
    trackEvent?.current(params)
  }

  const onSearchAutoSuggestOptionClick = (item: any, type?: string) => {
    if (type !== 'query') return

    const payload = {
      [DATA_LAYER_EVENT.ECOMMERCE.SEARCH_TERM]: item,
    }
    window?.dataLayer?.push({
      event: DATA_LAYER_EVENT.SEARCH,
      ...payload,
    })
    posthog.capture(DATA_LAYER_EVENT.SEARCH, payload)
  }

  if (isMobileView)
    return (
      <>
        <HeaderInput
          value=""
          loading={loader}
          inputRef={onSearchInputRef}
          placeholder={t('action.searchForDestinationsOrActivities')}
          asIcon={!!searchInputAsIcon}
          onClick={handleSearchInputClick}
          onClear={() => null}
        />
        <SearchModal
          loading={searching}
          destinationId={_destinationId}
          countryId={countryId}
          open={isSearchModalOpen}
          closeModal={closeSearchModal}
          initStateCompType={destinationId ? 'popular-activities' : 'destination'}
          onChange={doSearch}
          query={query}
          data={data}
          trackEvent={handleTrackEventCallback}
        />
      </>
    )

  return (
    <SearchAutocomplete
      variant="header"
      value={query}
      hasSearchIcon
      hideButton
      onChange={doSearch}
      onSubmit={() =>
        router.push(
          buildPath(
            SEARCH_ROUTE,
            {},
            {
              keyword: query,
              destinationIds: _destinationId,
              countryIds: countryId,
            }
          )
        )
      }
      placeholder={t('action.searchForDestinationsOrActivities')}
      maxDropdownWidth="auto"
      trackEvent={handleTrackEventCallback}
      initialStateComponent={
        <div className={s.searchDropdownCard}>
          <InitDropdown>
            <InitDropdown.RecentSearches onSelect={onSearchAutoSuggestOptionClick} />
            <InitDropdown.TrendingSearches onSelect={onSearchAutoSuggestOptionClick} />
          </InitDropdown>
        </div>
      }
    >
      <div className={s.searchDropdownCard}>
        <SearchAutoCompleteResults
          recommendType="auto_suggest"
          options={searchResults}
          onOptionClick={() => doSearch('')}
          className="mt-2"
        />
        <SearchAutoCompleteSeeMore
          value={query}
          onClick={() =>
            router.push(
              buildPath(SEARCH_ROUTE, undefined, {
                keyword: query,
                destinationIds: _destinationId,
                countryIds: countryId,
              })
            )
          }
          hidden={hideSeeMore}
          trackEvent={handleTrackEventCallback}
          flatButton={!searchResults.destinations?.length && !searchResults.products?.length}
        />
      </div>
    </SearchAutocomplete>
  )
}

export { Search }
