import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  MutableRefObject,
  useRef,
} from 'react'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
// eslint-disable-next-line import/order
import uuid from 'uuid-random'
// eslint-disable-next-line import/order
import { usePopupOverlaySequence } from 'lib/hooks/usePopupSequence'
// eslint-disable-next-line import/order
import {
  ProductOption as SearchAutocompleteProductOption,
  DestinationOption as SearchAutocompleteDestinationOption,
} from 'components/search-autocomplete'

import { useUserHistorySyncup } from 'lib/hooks/useUserHistorySyncup'

import { buildPath, getBrowserCookie, noop, setBrowserCookie } from 'lib/utils'
import { getStorageItem, setStorageItem } from 'lib/utils/web-storage'
import { ToastItem, ToastMethodParams, ToastTypeMethodParams } from 'lib/@Types'

import { SESSION_EXPIRED_ROUTE } from 'lib/constants/routes'
import {
  ACTIVITY_LOG,
  ACTIVITY_LOG_EXPIRY,
  COOKIES_PARTNER_SESSION_ID,
  COOKIES_RECENT_PDP_VISIT,
  COOKIES_RECENT_PDP_VISIT_EXPIRY,
  PARTNER_LIST,
} from 'lib/constants'

import { TRACK_CONTEXT_QUERY } from 'gql'

import { useDestinationCountryContext } from './destination-country-context'
import { useGlobalContext } from './global-context'

const partnerFeatureFlags = {
  default: {
    exitPromoBanner: true,
    helpcenterIcon: false,
    hamburgerMenu: true,
    wishlist: true,
    wishlistPromoBanner: true,
    kfMilesInfo: true,
    earnMilesCta: true,
    kfBanner: true,
    kfOnVpBanner: true,
    promo: true,
    authenticationCTA: true,
    redirectToPartnerOnCheckout: false,
    redirectToPartnerOnPayment: false,
    paymentHeader: true,
    paymentTnC: false,
    footer: true,
    fingerprint: true,
    signupCTA: true,
    viewBookings: true, // i.e. View Bookings
    location: true,
    shareIcon: true,
    progressbar: false,
    partialPaymentInfo: true, // Only if product / option has partial payment flag
    referralFeature: false,
  },
  krisplus: {
    exitPromoBanner: false,
    helpcenterIcon: true,
    hamburgerMenu: false,
    wishlist: false,
    wishlistPromoBanner: false,
    kfMilesInfo: false,
    earnMilesCta: true,
    kfBanner: false,
    kfOnVpBanner: false,
    promo: false,
    authenticationCTA: false,
    redirectToPartnerOnCheckout: true,
    redirectToPartnerOnPayment: true,
    paymentHeader: false,
    paymentTnC: true,
    footer: false,
    fingerprint: false,
    signupCTA: false,
    viewBookings: false,
    location: true,
    shareIcon: true,
    progressbar: true,
    partialPaymentInfo: false,
    referralFeature: false,
  },
}

type PartnerFeatures = typeof partnerFeatureFlags
type PartnerId = keyof PartnerFeatures
type FeatureName = keyof PartnerFeatures[PartnerId]

type DestinationCountry = {
  id: string
  name: string
  parentCountryName?: string // for destination that has country name
  code: string
  countryCode?: string // only country has this code
}

type AddActivityItemArgs = Partial<{
  keyword: string
  product: SearchAutocompleteProductOption
  destinationCountry: DestinationCountry
  destination: SearchAutocompleteDestinationOption
  created?: Date
}>
type AddActivityItemFn = (args: AddActivityItemArgs) => void

interface IAppDataContext {
  toast: {
    items: ToastItem[]
    remove: (id: string) => void
    notify: (item: ToastTypeMethodParams) => string
    success: (item: ToastTypeMethodParams) => string
    error: (item: ToastTypeMethodParams) => string
  }
  activityLog: {
    keywords: string[]
    products: SearchAutocompleteProductOption[]
    destinationCountries: DestinationCountry[]
    destinations: SearchAutocompleteDestinationOption[]
    logs: ActivityLog
    addItem: AddActivityItemFn
  }
  partnerFeatureControl: {
    showFeature: (arg0: FeatureName) => boolean
  }
  campaignData?: CampaignData
  enableGtm?: boolean
  isTTDUser?: boolean
  trackEvent: MutableRefObject<TrackEventType>
  setTrackEvent: (fn: TrackEventType) => void
  getTrackContextData: () => Promise<any>
}

export type ActivityLogItem<T> = {
  info: T
  created: Date
}[]

export interface ActivityLog {
  keywords: {
    text: string
    created: Date
  }[]
  products: ActivityLogItem<SearchAutocompleteProductOption>
  destinationCountries: ActivityLogItem<DestinationCountry>
  destinations: ActivityLogItem<SearchAutocompleteDestinationOption>
}

const removeExpiredItems = (items: { created: Date }[]) =>
  items?.filter?.(({ created }) => new Date().getTime() - new Date(created).getTime() <= ACTIVITY_LOG_EXPIRY)

const getNonExpiredActivityLogs = (activityLog: ActivityLog) => ({
  keywords: removeExpiredItems(activityLog?.keywords) || [],
  products: removeExpiredItems(activityLog?.products) || [],
  destinations: removeExpiredItems(activityLog?.destinations) || [],
  destinationCountries: removeExpiredItems(activityLog?.destinationCountries) || [],
})

type ActivityLogProducts = {
  info: SearchAutocompleteProductOption
  created: Date
}[]

const areSameProducts = (p1: ActivityLogProducts, p2: ActivityLogProducts) => {
  const p1Stringified = p1?.map?.(({ info }) => info.productId)?.join?.('')
  const p2Stringified = p2?.map?.(({ info }) => info.productId)?.join?.('')

  return p1Stringified === p2Stringified
}

const AppDataContext = createContext<IAppDataContext>({
  toast: {
    items: [],
    remove: () => undefined,
    notify: () => '',
    success: () => '',
    error: () => '',
  },
  activityLog: {
    keywords: [],
    products: [],
    destinations: [],
    destinationCountries: [],
    logs: {
      destinationCountries: [],
      destinations: [],
      keywords: [],
      products: [],
    },
    addItem: async () => undefined,
  },
  partnerFeatureControl: {
    showFeature: () => true,
  },
  trackEvent: { current: noop },
  setTrackEvent: noop,
  getTrackContextData: async () => undefined,
})

const AppDataProvider = (props: any) => {
  const router = useRouter()
  const trackContextData = useRef(null)
  const [getTrackContextQuery] = useLazyQuery(TRACK_CONTEXT_QUERY)
  const { countries, countriesByCode } = useDestinationCountryContext()
  const {
    globalArgs: { referralData },
  } = useGlobalContext()

  const [toastItems, setToastItems] = useState<ToastItem[]>([])
  const [activityLog, setActivityLog] = useState<ActivityLog>(() => {
    let _activityLog: any = getStorageItem(ACTIVITY_LOG)
    try {
      // this will also update the local storage and remove all expired logs as side effect
      _activityLog = getNonExpiredActivityLogs(JSON.parse(_activityLog) || {})
    } catch {}

    return {
      keywords: _activityLog.keywords || [],
      products: _activityLog.products || [],
      destinations: _activityLog.destinations || [],
      destinationCountries: _activityLog.destinationCountries || [],
    }
  })

  // Order for checking partner session info: queryParams first > check cookies > check Props
  let partnerWebviewCookie: any

  try {
    partnerWebviewCookie = getBrowserCookie(COOKIES_PARTNER_SESSION_ID)
      ? JSON.parse(getBrowserCookie(COOKIES_PARTNER_SESSION_ID) || '{}')
      : props?.ssrPartnerSessionInfo
  } catch {
    // Do nothing
  }

  const hasPartnerParams = useMemo(
    () =>
      (PARTNER_LIST.includes(router.query?.partnerId as string) && !!router.query?.sessionId) ||
      !!(partnerWebviewCookie?.partnerId && partnerWebviewCookie?.sessionId),
    [
      partnerWebviewCookie?.partnerId,
      partnerWebviewCookie?.sessionId,
      router.query?.partnerId,
      router.query?.sessionId,
    ]
  )

  // referral feature flag handling
  useEffect(() => {
    partnerFeatureFlags.default.referralFeature = referralData?.isEnabled
    partnerFeatureFlags.krisplus.referralFeature = referralData?.isEnabled
  }, [referralData])

  useEffect(() => {
    let destinationCountries: ActivityLog['destinationCountries'] = []
    const hasCountryData = countries ? Object.keys(countries).length > 0 : false
    const hasCountryUriData = countriesByCode ? Object.keys(countriesByCode).length > 0 : false
    const hasDestinationCountries =
      activityLog.destinationCountries && activityLog.destinationCountries.length > 0
    if (hasDestinationCountries && hasCountryData && hasCountryUriData) {
      destinationCountries = activityLog.destinationCountries.map((destinationCountry) => {
        // Add missing country code if not exist
        // for user who have older recently viewed data
        if (!destinationCountry.info.parentCountryName && !destinationCountry.info.countryCode) {
          const countryData =
            countriesByCode[destinationCountry.info.id] || countries[destinationCountry.info.id]
          return {
            ...destinationCountry,
            info: {
              ...destinationCountry.info,
              countryCode: countryData?.countryId || '',
            },
          }
        }

        return destinationCountry
      })

      setStorageItem(
        ACTIVITY_LOG,
        JSON.stringify({
          ...activityLog,
          destinationCountries,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, countriesByCode])

  useEffect(() => {
    if (hasPartnerParams && partnerWebviewCookie?.expires && Date.now() > partnerWebviewCookie?.expires) {
      router.push(buildPath(SESSION_EXPIRED_ROUTE))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPartnerParams, router.asPath])

  useEffect(() => {
    const callback = (event: StorageEvent) => {
      try {
        if (event.storageArea != localStorage) return
        if (event.key === ACTIVITY_LOG) {
          const { products }: ActivityLog = JSON.parse(event.newValue || '') || {}
          if (areSameProducts(activityLog.products, products)) return
          setActivityLog((curr) => ({ ...curr, products }))
          setBrowserCookie(COOKIES_RECENT_PDP_VISIT, `${!!products?.length}`, COOKIES_RECENT_PDP_VISIT_EXPIRY)
        }
      } catch {}
    }
    window?.addEventListener?.('storage', callback)
    return () => window?.removeEventListener?.('storage', callback)
  }, [activityLog.products])

  // Sync user activity recieved from server with local storage
  const { userActivity } = useUserHistorySyncup({ activityLog })
  useEffect(() => {
    if (userActivity) {
      setActivityLog((curr) => {
        const newLog = {
          ...curr,
          ...userActivity,
        }
        setStorageItem(ACTIVITY_LOG, JSON.stringify(newLog))
        return newLog
      })
    }
  }, [userActivity])
  // end of sync user activity

  const trackEventRef = useRef<TrackEventType>(noop)

  const getTrackContextData: any = useCallback(async () => {
    if (trackContextData.current) return Promise.resolve(trackContextData.current)

    const { data } = await getTrackContextQuery()

    if (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ['dev', 'qa', 'stage'].includes(process.env.NEXT_PUBLIC_APP_ENV!) &&
      data?.trackContext?.output?.geoCity
    )
      console.log('geo city from ngnix ==>', data?.trackContext?.output?.geoCity)

    trackContextData.current = data?.trackContext?.output || {}
    return trackContextData.current
  }, [getTrackContextQuery])

  const removeToastItem = useCallback((id: string) => {
    setToastItems((curr) => curr?.filter?.((item) => item.id !== id))
  }, [])

  const notifyToast = useCallback((params: ToastMethodParams) => {
    const id = params.id || uuid()
    const created = Date.now()

    setToastItems((curr) => {
      // update toast if id found, or else create new entry in toast items
      if (curr?.find((item) => item.id === id)) {
        return curr.map((item) => (item.id === id ? { ...params, id, created } : item))
      }
      return [...curr, { ...params, id, created }]
    })
    return id
  }, [])

  const successToast = useCallback(
    (params: ToastTypeMethodParams) => notifyToast({ ...params, type: 'success' }),
    [notifyToast]
  )

  const errorToast = useCallback(
    (params: ToastTypeMethodParams) => notifyToast({ ...params, type: 'error' }),
    [notifyToast]
  )

  const addToActivityLog: AddActivityItemFn = useCallback(
    ({ keyword, product, destinationCountry, destination }) => {
      if (keyword) {
        // clear the keyword if already existed
        const _keywords = activityLog.keywords?.filter?.((item) => item.text !== keyword)
        setActivityLog((curr) => {
          const newLog = {
            ...curr,
            keywords: [{ text: keyword, created: new Date() }, ..._keywords].slice(0, 3),
          }
          setStorageItem(ACTIVITY_LOG, JSON.stringify(newLog))
          return newLog
        })
      } else if (product) {
        const _products = activityLog.products?.filter?.((item) => item.info?.productId !== product.productId)
        setActivityLog((curr) => {
          const newLog = {
            ...curr,
            products: [{ info: product, created: new Date() }, ..._products].slice(0, 8),
          }
          setStorageItem(ACTIVITY_LOG, JSON.stringify(newLog))
          setBrowserCookie(
            COOKIES_RECENT_PDP_VISIT,
            `${!!newLog?.products?.length}`,
            COOKIES_RECENT_PDP_VISIT_EXPIRY
          )
          return newLog
        })
      } else if (destinationCountry) {
        const _destinationCountries = activityLog.destinationCountries?.filter(
          (item) => item.info?.id !== destinationCountry.id
        )

        setActivityLog((curr) => {
          const newLog = {
            ...curr,
            destinationCountries: [
              { info: destinationCountry, created: new Date() },
              ..._destinationCountries,
            ].slice(0, 2),
          }
          setStorageItem(ACTIVITY_LOG, JSON.stringify(newLog))
          return newLog
        })
      } else if (destination) {
        const _destinations = activityLog.destinations?.filter(
          (item) => item.info?.destinationId !== destination.destinationId
        )

        setActivityLog((curr) => {
          const newLog = {
            ...curr,
            destinations: [{ info: destination, created: new Date() }, ..._destinations].slice(0, 3),
          }
          setStorageItem(ACTIVITY_LOG, JSON.stringify(newLog))
          return newLog
        })
      }
    },
    [activityLog]
  )

  const setTrackEvent = useCallback((fn: TrackEventType) => {
    trackEventRef.current = fn
  }, [])

  const activityLogsWithoutExpiry = useMemo(
    () => ({
      products: activityLog.products?.map((p) => p.info),
      keywords: activityLog.keywords?.map((k) => k.text),
      destinations: activityLog.destinations?.map((d) => d.info),
      destinationCountries: activityLog.destinationCountries?.map((dc) => dc.info),
    }),
    [activityLog]
  )

  const showFeature = useCallback(
    (feature: FeatureName) => {
      if (hasPartnerParams && partnerWebviewCookie?.partnerId) {
        return hasPartnerParams
          ? partnerFeatureFlags?.[partnerWebviewCookie?.partnerId as PartnerId]?.[feature] ?? true
          : true
      }

      // Check default feature flag first in case its non-partner view
      return partnerFeatureFlags.default?.[feature] ?? true
    },
    [hasPartnerParams, partnerWebviewCookie?.partnerId]
  )

  const {
    isGtm: enableGtm,
    campaignData,
    isTTDUser,
  } = usePopupOverlaySequence({
    isPromoEnabled: props?.isPromoEnabled,
  })

  const value = useMemo<IAppDataContext>(
    () => ({
      toast: {
        items: toastItems,
        remove: removeToastItem,
        notify: notifyToast,
        success: successToast,
        error: errorToast,
      },
      activityLog: {
        ...activityLogsWithoutExpiry,
        logs: activityLog,
        addItem: addToActivityLog,
      },
      partnerFeatureControl: {
        showFeature,
      },
      campaignData,
      enableGtm,
      isTTDUser,
      trackEvent: trackEventRef,
      setTrackEvent,
      getTrackContextData,
    }),
    [
      toastItems,
      removeToastItem,
      notifyToast,
      successToast,
      errorToast,
      activityLog,
      activityLogsWithoutExpiry,
      addToActivityLog,
      showFeature,
      campaignData,
      isTTDUser,
      enableGtm,
      setTrackEvent,
      getTrackContextData,
    ]
  )

  return <AppDataContext.Provider value={value} {...props} />
}

function useAppData() {
  const context = React.useContext(AppDataContext)
  if (context === undefined) {
    throw new Error('useAppData must be used within a AppDataProvider')
  }
  return context
}

export { AppDataProvider, useAppData }
